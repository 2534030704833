import TYPES from '@/types';

// Application
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import UpdateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/update-customer-investor-goal-reminder-command';

// Domain
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditReminderViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.UPDATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
  private readonly update_investor_goal_reminder_cmd!: UpdateCustomerInvestorGoalReminderCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.kuspit-dashboard.edit-reminder.edit_reminder';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: this.translate('select_option', { option: i + 1 }), value: i + 1 }));

  associated_product_id = '';

  customer_investor_goal_reminder_id = '';

  day_to_remind = 1;

  is_subscribed = false;

  is_disabled = true;

  show_success_modal = false;

  private customer_reminder_entity: CustomerInvestorGoalReminderEntity = {
    id: '',
    day_to_remind: 0,
    investment_product_id: '',
  }

  initialize = async (associated_product_id: string) => {
    this.associated_product_id = associated_product_id;
    await this.loadCustomerInvestorGoalReminder();
  }

  selectedDayToRemind = () => {
    this.is_disabled = false;
  }

  loadCustomerInvestorGoalReminder = async () => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({ associated_product_id: this.associated_product_id });
      this.customer_investor_goal_reminder_id = customer_investor_goal_reminder.id;
      this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
      this.is_subscribed = !customer_investor_goal_reminder.is_subscribed || false;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  updateCustomerInvestorGoalReminder = async () => {
    try {
      this.customer_reminder_entity.id = this.customer_investor_goal_reminder_id;
      this.customer_reminder_entity.investment_product_id = this.associated_product_id;
      this.customer_reminder_entity.day_to_remind = this.day_to_remind;
      this.customer_reminder_entity.is_subscribed = !this.is_subscribed;
      await this.update_investor_goal_reminder_cmd.execute(this.customer_reminder_entity);
      const reload_monthly_reminder_info = new CustomEvent('reload.pocket.monthly.reminder.information', { detail: { } });
      window.dispatchEvent(reload_monthly_reminder_info);
      this.show_success_modal = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_customer_investor_goal_reminder'));
    }
  };
}
