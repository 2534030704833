

























































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import EditReminderViewModel from '@/vue-app/view-models/components/kuspit-dashboard/edit-reminder/edit-reminder-view-model';

@Component({
  name: 'EditReminder',
  components: {
    EditReminderSuccessModal: () => import('./EditReminderSuccessModal.vue'),
  },
})
export default class EditReminder extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  edit_reminder_model = Vue.observable(
    new EditReminderViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  closeSuccessModal = () => {
    this.edit_reminder_model.is_disabled = true;
    this.edit_reminder_model.show_success_modal = false;
    this.closeModal();
  }

  created() {
    this.edit_reminder_model.initialize(this.associated_product_id);
  }
}
